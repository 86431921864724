import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToggleSubscriptionStatus } from '@app/store/app.actions';
import { AppState } from '@app/store/app.state';
import { Subscription, SubscriptionStatus } from '@core/models/notification.model';
import { Store } from '@ngxs/store';
import { debounceTime, delay, filter, startWith } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonLoaderComponent } from '../../../shared/modules/skeleton-loader/skeleton-loader.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-notification-subscription',
  templateUrl: './notification-subscription.component.html',
  styleUrls: ['./notification-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggleModule, SkeletonLoaderComponent, TranslateModule],
})
export class NotificationSubscriptionComponent {
  public subscriptions: Subscription[];
  SubscriptionStatus = SubscriptionStatus;
  @Output() setShowAllEmmiter?: EventEmitter<any> = new EventEmitter();
  @Input() showAll;
  constructor(
    private _store: Store,
    private _cdr: ChangeDetectorRef,
  ) {
    this._store
      .select(AppState.subscriptions)
      .pipe(
        filter((_) => !!_),
        debounceTime(1000),
        delay(400),
        startWith(this._store.selectSnapshot(AppState.subscriptions)),
        takeUntilDestroyed(),
      )
      .subscribe((res) => {
        this.subscriptions = res;
        this._cdr.markForCheck();
      });
  }

  toggleSubscription(id) {
    this._store.dispatch(new ToggleSubscriptionStatus(id));
  }
}
