import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { MatDividerModule } from '@angular/material/divider';
import { NotificationSubscriptionComponent } from '../notification-subscription/notification-subscription.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    RouterLink,
    TranslateModule,
    NotificationSubscriptionComponent,
    MatDividerModule,
    NotificationListComponent,
  ],
})
export class NotificationOverviewComponent {
  @Input() showNavigation = true;
  @Input() collapsableSettingsOpen;
  @Input() notifications: Notification[];
  @Input() showAll;
  @Output() setShowAllEmmiter?: EventEmitter<any> = new EventEmitter();
  @Output() closeOverlayEmmiter?: EventEmitter<any> = new EventEmitter();
}
