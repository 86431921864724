import { Injectable, inject } from '@angular/core';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngxs/store';
import { Observable, filter, retry, throwError } from 'rxjs';

@Injectable()
export class OfflineInterceptor {
  private _store = inject(Store);
  appOnline$: Observable<boolean> = this._store.select(AppState.appOnline);

  intercept(req, next) {
    return next.handle(req).pipe(
      retry({
        delay: (errors) => {
          if (!!this._store.selectSnapshot(AppState.appOnline)) {
            return throwError(() => errors);
          }
          return this.appOnline$.pipe(filter((_) => !!_));
        },
      })
    );
  }
}
