import { MediaMatcher } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  signal,
  Signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Campaign } from '@app/features/campaign/models/campaign.model';
import { AppState } from '@app/store/app.state';
import { BusinessModule, BusinessType } from '@auth/models/auth.model';
import { AuthorizationService } from '@auth/services/authorization.service';
import { CampaignState } from '@campaign/store/campaign.state';
import { NotificationService } from '@core/services/notification/notification.service';
import { ThemingService } from '@core/services/theming/theming.service';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SiteManagementState } from '@site-management/store/site-management.state';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgProgressModule } from 'ngx-progressbar';
import { interval, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { NotificationMenuComponent } from '@core/components/notification-menu/notification-menu.component';
import { ProfileMenuComponent } from '@core/components/profile-menu/profile-menu.component';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    RouterLink,
    NotificationMenuComponent,
    ProfileMenuComponent,
    NgProgressModule,
    MatSidenavModule,
    MatListModule,
    RouterLinkActive,
    MatBadgeModule,
    BreadcrumbsComponent,
    RouterOutlet,
    AsyncPipe,
    TranslateModule,
    CommonModule,
    AngularSvgIconModule,
  ],
})
export class LayoutComponent {
  public mobileQuery$ = signal<MediaQueryList>(null);
  protected readonly environment = environment;
  protected readonly BusinessType = BusinessType;
  private store = inject(Store);
  version$: Observable<string> = inject(Store).select(AppState.version);
  missingOrRejected$: Observable<boolean> = inject(Store).select(
    CampaignState.missingOrRejectedAssetsForCurrentCampaign
  );
  campaignStatisticsNotAvailable$: Observable<boolean> = inject(Store).select(
    CampaignState.currentCampaignStatisticsNotAvailable
  );
  assessments$: Observable<any> = inject(Store).select(SiteManagementState.pendingAssessmentsList);
  currentCampaign: Signal<Campaign> = toSignal(inject(Store).select(CampaignState.currentCampaign));

  public primaryColor$ = signal<string>(null);
  protected readonly BusinessModule = BusinessModule;

  private sideNav$ = viewChild<MatSidenav>('sideNav');
  private displayLargeBreakpoint: string;
  sessionStorage = sessionStorage;

  public modules$: Signal<boolean> = toSignal(this._authorizationService.modules$, { initialValue: null });
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private media: MediaMatcher,
    public _authorizationService: AuthorizationService,
    private _themingService: ThemingService,
    private _cd: ChangeDetectorRef,
    _notificationService: NotificationService
  ) {
    this.primaryColor$.set(this._themingService.primary);
    this.displayLargeBreakpoint = this._themingService.getStyleVariable('breakpoint-display-large');
    this.setMobileQuery();
    this.router.events
      .pipe(takeUntilDestroyed())
      .subscribe(
        (event) =>
          !!this.mobileQuery$()?.matches &&
          this.sideNav$()?.opened &&
          event instanceof NavigationEnd &&
          this.sideNav$().close() &&
          this._cd.markForCheck()
      );
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setMobileQuery();
  }

  ngAfterViewInit() {
    if (!sessionStorage.getItem('app-loaded')) {
      interval(1500)
        .pipe(take(1))
        .subscribe(() => {
          sessionStorage.setItem('app-loaded', 'true');
        });

      window.addEventListener('beforeunload', () => {
        sessionStorage.removeItem('app-loaded');
      });
    }
  }

  private setMobileQuery() {
    this.mobileQuery$?.set(this.media.matchMedia(`(max-width: ${this.displayLargeBreakpoint})`));
  }
}
