import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Signal, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { AuthorizationService } from '@auth/services/authorization.service';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

const ROUTE_DATA_BREADCRUMB = 'titleKey';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLinkActive, RouterLink, MatIconModule, TranslateModule],
})
export class BreadcrumbsComponent {
  breadcrumbs: Breadcrumb[];
  public modules: Signal<any>;
  private destroyRef = inject(DestroyRef);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _authorizationService: AuthorizationService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.modules = toSignal(this._authorizationService.modules$, { initialValue: null });
    this._cdr.markForCheck();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
        this.breadcrumbs = this.breadcrumbs.length > 1 ? this.breadcrumbs : [];
        this._cdr.markForCheck();
      });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }
    for (let child of children) {
      if (!child.snapshot.data?.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (!!routeURL) {
        url += `/${routeURL}`;
      }
      const breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url: url,
      };
      if (!breadcrumbs.find((b) => b.url == breadcrumb.url || b.label == breadcrumb.label)) {
        breadcrumbs.push(breadcrumb);
      }

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}
export interface Breadcrumb {
  label: string;
  url: string;
}
