import { MediaMatcher } from '@angular/cdk/layout';
import { ConnectionPositionPair, CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ToggleSubscriptionStatus } from '@app/store/app.actions';
import { AppState } from '@app/store/app.state';
import { NotificationModel } from '@core/models/notification.model';
import { ThemingService } from '@core/services/theming/theming.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { NotificationOverviewComponent } from '../notification-overview/notification-overview.component';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    CdkOverlayOrigin,
    MatTooltipModule,
    MatIconModule,
    MatBadgeModule,
    CdkConnectedOverlay,
    MatCardModule,
    NotificationOverviewComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class NotificationMenuComponent {
  isOpen = false;
  mobileQuery: MediaQueryList;
  collapsableSettingsOpen = false;
  positionPairsSmallerScreen: ConnectionPositionPair[] = [
    {
      offsetY: 45,
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      panelClass: 'overlay-wrapper',
    },
  ];
  positionPairs: ConnectionPositionPair[] = [
    {
      offsetY: 45,
      panelClass: 'overlay-wrapper',
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];
  numberOfUnreadNotifications$: Observable<number> = this._store.select(AppState.numberOfUnreadNotifications);
  allNotifications$: Observable<NotificationModel[]> = this._store.select(AppState.notifications);
  unreadNotifications$: Observable<NotificationModel[]> = this._store.select(AppState.unreadNotifications);

  public notifications$: Observable<NotificationModel[]>;
  public showAll = false;

  constructor(
    private _themingService: ThemingService,
    private media: MediaMatcher,
    private _store: Store,
    private _cdr: ChangeDetectorRef
  ) {
    const displayLargeBreakpoint = this._themingService.getStyleVariable('breakpoint-display-large');
    this.mobileQuery = this.media.matchMedia(`(max-width: ${displayLargeBreakpoint})`);
    this.notifications$ = this.unreadNotifications$;
    this._cdr.markForCheck();
  }

  toggleSubscription(id) {
    this._store.dispatch(new ToggleSubscriptionStatus(id));
  }

  setShowAll(showAll?) {
    this.showAll = showAll ? showAll : !this.showAll;
    this.notifications$ = this.showAll ? this.allNotifications$ : this.unreadNotifications$;
    this._cdr.markForCheck();
  }

  closeOverlay() {
    this.isOpen = false;
    this.collapsableSettingsOpen = false;
    this._cdr.markForCheck();
  }
}
