<mat-toolbar color="accent" class="sticky-header no-print">
  @if (mobileQuery$()?.matches) {
  <button mat-icon-button (click)="sidenav.toggle()" id="lyt_btn_side_nav">
    <mat-icon matIconPrefix>menu</mat-icon>
  </button>
  }

  <a [routerLink]="['/']" id="lyt_link_routto_root">
    <svg-icon
      [src]="environment.assetsUrl + 'logo-white.svg'"
      [svgAriaLabel]="'Adtrac logo white'"
      [svgClass]="sessionStorage.getItem('app-loaded') ? 'logo no-animation' : 'logo'"
    ></svg-icon>
  </a>

  <div class="flex center">
    <app-notification-menu />
    <!-- @if (!mobileQuery.matches) { -->
    <app-profile-menu [mobileQuery]="mobileQuery$()?.matches" />
    <!-- } -->
  </div>
</mat-toolbar>
<ng-progress [spinner]="false" [color]="primaryColor$()" [meteor]="false" [thick]="true" [trickleSpeed]="100" />

<mat-sidenav-container autosize>
  <mat-sidenav
    #sidenav
    id="lyt_nav_sidenav"
    [mode]="mobileQuery$()?.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery$()?.matches"
    [opened]="!mobileQuery$()?.matches"
    [disableClose]="!mobileQuery$()?.matches"
    class="no-print"
  >
    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="['/']"
        routerLinkActive="active"
        id="lyt_link_routto_root"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="mobileQuery$()?.matches && sidenav.close()"
      >
        <mat-icon>home</mat-icon>
        <span>{{ 'home.title' | translate }}</span></a
      >
      @if (!!modules$()?.[BusinessModule?.campaign]) {
      <a mat-list-item [routerLink]="['/campaigns']" routerLinkActive="active" id="lyt_link_routto_cmpgn">
        <mat-icon>view_headline</mat-icon>
        <span>{{ 'campaign.campaigns' | translate }}</span></a
      >
      } @if (router.url.includes('campaigns/detail')) { @if ((route.firstChild.firstChild.params | async)['campaignId'];
      as campaignId) {
      <a
        id="lyt_link_routto_cmpgn_dtl"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['detail', campaignId]"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>campaign</mat-icon>
        <span>{{ 'campaign.campaign' | translate }}</span></a
      >

      <a
        id="lyt_link_routto_location"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['detail', campaignId, 'locations']"
      >
        <mat-icon>location_on</mat-icon>
        <span>{{ 'campaign.detail.locations' | translate }}</span></a
      >

      <a
        id="lyt_link_routto_media"
        [disabled]="!!currentCampaign()?.standalone"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="!currentCampaign()?.standalone ? ['detail', campaignId, 'media'] : null"
      >
        <mat-icon>movie</mat-icon>
        <span>{{ 'campaign.detail.media' | translate }}</span>
        @if (!currentCampaign()?.standalone) {
        <mat-icon color="warn" [style.opacity]="!!(missingOrRejected$ | async) ? '1' : '0'">error_outline</mat-icon>
        }
      </a>

      @if (!(campaignStatisticsNotAvailable$ | async)) {
      <a
        id="lyt_link_routto_playouts"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['detail', campaignId, 'playouts']"
      >
        <mat-icon>assessment</mat-icon>
        <span>{{ 'campaign.detail.playouts' | translate }}</span>
      </a>
      } @else {
      <a id="lyt_link_routto_playouts" mat-list-item class="submenu submenu-disabled" disabled>
        <mat-icon>assessment</mat-icon>
        <span>{{ 'campaign.detail.playouts' | translate }}</span>
      </a>
      } } } @if (!!environment?.['guaranteedCampaign']) {
      <a
        id="lyt_link_routto_blocker_campaign"
        mat-list-item
        routerLinkActive="active"
        [routerLink]="['/guaranteed']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>verified</mat-icon>
        <span>{{ 'guaranteed.title' | translate }}</span></a
      >
      } @if (router.url.includes('guaranteed/detail')) { @if ((route.firstChild.firstChild.params |
      async)['campaignId']; as campaignId) {
      <a
        id="lyt_link_routto_cmpgn_dtl"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['detail', campaignId]"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>campaign</mat-icon>
        <span>{{ 'campaign.campaign' | translate }}</span></a
      >

      <a
        id="lyt_link_routto_location"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['detail', campaignId, 'locations']"
      >
        <mat-icon>location_on</mat-icon>
        <span>{{ 'campaign.detail.locations' | translate }}</span></a
      >
      } }
      <a
        id="lyt_link_routto_media_library"
        mat-list-item
        routerLinkActive="active"
        [routerLink]="['/media-library']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>video_library</mat-icon>
        <span>{{ 'mediaLibrary.title' | translate }}</span></a
      >
      @if (!!modules$()?.[BusinessModule.site]) {
      <a mat-list-item id="lyt_link_routto_publisher" [routerLink]="['/publisher']" routerLinkActive="active">
        <mat-icon>cast</mat-icon>
        <span>{{ 'site.siteManagementNavigation' | translate }}</span></a
      >
      } @if (router.url.includes('publisher')) {
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/publisher/sites/']"
        routerLinkActive="active"
        id="lyt_link_routto_publisher_site"
      >
        <mat-icon>place</mat-icon>
        <span>{{ 'home.sites' | translate }}</span></a
      >
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/publisher/assessment']"
        routerLinkActive="active"
        id="lyt_link_routto_publisher_assessment"
      >
        <mat-icon>checklist_rtl</mat-icon>
        <span>Assessments</span>
        @if ((assessments$ | async)?.length; as assessmentsLength) {
        <span
          [matBadge]="assessmentsLength"
          matBadgeOverlap="false"
          class="assessments-badge"
          id="assessments-badge"
        ></span>
        }
      </a>
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/publisher/report']"
        routerLinkActive="active"
        id="lyt_link_routto_publisher_report"
      >
        <mat-icon>show_chart</mat-icon>
        <span>{{ 'salesChannel.report.title' | translate }}</span></a
      >
      } @if (!!modules$()?.[BusinessModule.user]) {
      <a id="lyt_link_routto_users" mat-list-item [routerLink]="['/users']" routerLinkActive="active">
        <mat-icon>people</mat-icon>
        <span>{{ 'user.userManagementNavigation' | translate }}</span></a
      >
      } @if (!!modules$()?.[BusinessModule.tenant]) {
      <a
        id="lyt_link_routto_tenants"
        mat-list-item
        [routerLink]="['/tenants']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>business</mat-icon>
        <span>{{ 'tenant.title' | translate }}</span></a
      >
      }
      <a
        id="lyt_link_routto_clients"
        mat-list-item
        [routerLink]="['/tenants/clients']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>contact_page</mat-icon>
        <span>{{ 'tenant.clients' | translate }}</span></a
      >

      @if (!!modules$()?.[BusinessModule.salesChannel]) {
      <a id="lyt_link_routto_sales" mat-list-item [routerLink]="['/sales-channel']" routerLinkActive="active">
        <mat-icon>storefront</mat-icon>
        <span>{{ 'salesChannel.title' | translate }}</span></a
      >
      } @if (router.url.includes('sales-channel')) {
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/sales-channel/price-modifiers']"
        routerLinkActive="active"
        id="lyt_link_routto_sales_report"
      >
        <mat-icon>sell</mat-icon>
        <span>{{ 'salesChannel.discountsAndSurcharges.title' | translate }}</span></a
      >
      @if(_authorizationService.businessTypes$ | async; as hasBusinessType) {
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="!!hasBusinessType[BusinessType.PlATFORMHOST] ? ['/sales-channel/scenario'] : null"
        [disabled]="!hasBusinessType[BusinessType.PlATFORMHOST]"
        routerLinkActive="active"
        id="lyt_link_routto_sales_report"
      >
        <mat-icon>menu_book</mat-icon>
        <span>{{ 'salesChannel.scenario.scenarios' | translate }}</span></a
      >
      }
      <!-- <a
          id="lyt_link_routto_sales_credit_note"
          mat-list-item
          routerLinkActive="active-sub"
          class="submenu"
          [routerLink]="['/sales-channel/credit-note']"
          routerLinkActive="active"
        >
          <mat-icon>card_giftcard</mat-icon>
          <span>{{ 'salesChannel.creditNote.title' | translate }}</span></a
        > -->
      <a
        id="lyt_link_routto_sales_template"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/sales-channel/template']"
        routerLinkActive="active"
      >
        <mat-icon>description</mat-icon>
        {{ 'salesChannel.template.title' | translate }}</a
      >

      <a
        id="lyt_link_routto_sales_invoice"
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/sales-channel/invoice']"
        routerLinkActive="active"
      >
        <mat-icon>receipt_long</mat-icon>
        <span>{{ 'salesChannel.invoice.title' | translate }}</span></a
      >
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/sales-channel/billing']"
        routerLinkActive="active"
        id="lyt_link_routto_sales_billing"
      >
        <mat-icon>ios_share</mat-icon>
        <span>{{ 'salesChannel.billing.title' | translate }}</span></a
      >
      <a
        mat-list-item
        routerLinkActive="active-sub"
        class="submenu"
        [routerLink]="['/sales-channel/report']"
        routerLinkActive="active"
        id="lyt_link_routto_sales_report"
      >
        <mat-icon>show_chart</mat-icon>
        <span>{{ 'salesChannel.report.title' | translate }}</span></a
      >
      } @if (!!environment?.poweredByAdtrac) {
      <img [src]="environment.assetsUrl + 'powered-by-adtrac.png'" class="powered-by" alt="Adtrac" />
      } @if (!mobileQuery$()?.matches) {
      <small class="version-banner"> {{ 'version' | translate }} {{ version$ | async }}</small>
      }
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content [class.expanded]="!mobileQuery$()?.matches">
    <div class="view-container p-4">
      <app-breadcrumbs />
      <router-outlet />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
