import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterLink } from '@angular/router';
import { BusinessModule } from '@auth/models/auth.model';
import { AuthorizationService } from '@auth/services/authorization.service';
import { LoginService } from '@auth/services/login.service';
import { LANGUAGES } from '@core/services/language/language.model';
import { LanguageService } from '@core/services/language/language.service';
import { UserProfileService } from '@core/services/user-profile/user-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-profile-menu',
    templateUrl: './profile-menu.component.html',
    styleUrls: ['./profile-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatMenuModule, MatIconModule, RouterLink, AsyncPipe, TranslateModule]
})
export class ProfileMenuComponent {
  @Input() mobileQuery: boolean;
  languages = LANGUAGES;
  BusinessModule = BusinessModule;
  public modules: Signal<any>;
  constructor(
    public languageService: LanguageService,
    public userProfileService: UserProfileService,
    private loginService: LoginService,
    public router: Router,
    private _authorizationService: AuthorizationService,
    private _cdr: ChangeDetectorRef
  ) {
    this.modules = toSignal(this._authorizationService.modules$, { initialValue: null });
  }

  setLanguage(language: string): void {
    this.languageService.updateLanguage(language);
    this._cdr.markForCheck();
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  changePassword() {
    this.router.navigate(['/login/change-password']);
  }
}
