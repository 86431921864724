import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval, map, takeWhile, timer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';

@Component({
    selector: 'app-update',
    templateUrl: './app-update.component.html',
    styleUrls: ['./app-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatRippleModule, MatTooltipModule, MatIconModule, MatButtonModule, DatePipe, TranslateModule]
})
export class AppUpdateComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  public updateTimer = 5 * 60;
  public infoExpanded = false;

  private _cdr = inject(ChangeDetectorRef);
  timeRemaining: number;
  @Output() updateApplication = new EventEmitter<void>();

  ngOnInit(): void {
    timer(0, 1000)
      .pipe(
        map(n => (this.updateTimer - n) * 1e3),
        takeWhile(n => n >= 0)
      )
      .subscribe(timeRemaining => {
        this.timeRemaining = timeRemaining;
        this._cdr.markForCheck();
      });

    interval(this.updateTimer * 1e3)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(_ => this.startApplicationUpdate());
  }

  startApplicationUpdate() {
    this.updateApplication.emit();
  }

  handleInfoClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.infoExpanded = !this.infoExpanded;
  }
}
