import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AppState } from '@app/store/app.state';
import { LanguageService } from '@core/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AppUpdateComponent } from '@shared/components/app-update/app-update.component';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { TermsAndConditionsService } from '@shared/services/terms-and-conditions.service';
import moment from 'moment';
import { Observable, Subject, filter, interval, switchMap, take } from 'rxjs';
import { environment } from '@app/environments/environment';
import { AuthState } from '@auth/store/auth.state';
import { OfflineBannerComponent } from '@shared/components/offline-banner/offline-banner.component';
import { SetUpdateAvailable } from '@app/store/app.actions';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AppUpdateComponent, OfflineBannerComponent, AsyncPipe, RouterOutlet],
  standalone: true,
})
export class AppComponent implements OnInit {
  public closed$ = new Subject<void>();
  public environment = environment;
  version$: Observable<string> = inject(Store).select(AppState.version);
  updateAvailable$: Observable<boolean> = inject(Store).select(AppState.updateAvailable);
  updateAvailable = inject(Store).selectSnapshot(AppState.updateAvailable);
  private destroyRef = inject(DestroyRef);
  private lastUpdateAvailableTime$ = inject(Store).select(AppState.lastUpdateAvailableTime);
  constructor(
    _googleAnalyticsService: GoogleAnalyticsService,
    private _router: Router,
    private _swUpdate: SwUpdate,
    public _tcService: TermsAndConditionsService,
    private titleService: Title,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _languageService: LanguageService,
    private store: Store,
    private renderer: Renderer2,
    private _cd: ChangeDetectorRef
  ) {
    // If update available and not updated since more than 5 minutes
    this.lastUpdateAvailableTime$
      .pipe(
        take(1),
        filter(
          (x: number) => !!x && !!this.updateAvailable && moment(new Date().getTime()).diff(moment(x), 'minutes') > 5
        )
      )
      .subscribe(() => this.activateUpdate());
    this.checkForUpdate();
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
        switchMap((event: NavigationEnd) => {
          if (event.url.includes('login')) {
            // Update app automatically if on login page
            !!this.updateAvailable && this.activateUpdate();
          }

          gtag('event', 'page_view', {
            page_title: event.urlAfterRedirects,
            page_location: event.urlAfterRedirects,
            page_path: event.urlAfterRedirects,
            send_to: 'G-E1Z7B5ZHVV',
          });
          return this.store.select<string>(AppState.appName).pipe(takeUntilDestroyed(this.destroyRef));
        }),
        filter((_) => !!_)
      )
      .subscribe((appName) => {
        this.updateTitle(appName);
      });
  }

  updateTitle(appName) {
    const titleKey = this._route.root.firstChild.snapshot.data['titleKey'];
    this.titleService.setTitle(`${appName} ${!!titleKey ? '| ' + this._translateService.instant(titleKey) : ''}`);
    this._cd.markForCheck();
  }

  ngOnInit() {
    this._languageService.init();

    !!environment.production &&
      interval(60 * 1e3)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((_) => this.checkForUpdate());
  }

  activateUpdate() {
    this.store.dispatch(new SetUpdateAvailable(false, null));
    this._swUpdate.activateUpdate().then((activated) => {
      if (activated) {
        const localStorageKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
          if (!['auth', 'app', 'sales', 'tenant'].includes(localStorage.key(i))) {
            localStorageKeys.push(localStorage.key(i));
          }
        }
        localStorageKeys.forEach((key) => {
          localStorage.removeItem(key);
        });
        const auth = this.store.selectSnapshot(AuthState as any);
        this.store.reset({
          auth,
          app: { appOnline: true, adblockPresent: false, updateAvailable: false, lastUpdateAvailableTime: null },
        });

        location.reload();
        this._cd.markForCheck();
      }
    });
  }

  /* SW Update */
  checkForUpdate() {
    if (this._swUpdate && this._swUpdate.isEnabled) {
      this._swUpdate.checkForUpdate().then((available) => {
        if (available && !this.updateAvailable) {
          this.store.dispatch(new SetUpdateAvailable(true, new Date().getTime()));
        }
      });
    }
  }
}
